import React, { forwardRef, useState, useEffect, useCallback } from "react";
import "./enterprises.scss";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import InputText from "../../components/inputText/inputText";
import {ItemType} from "../../components/select/select";
import { EnterpriseModel, EnterpriseType } from "../../models/enterprise.model";
import EnterpriseService from "../../services/enterprises.service";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

interface Props { }

const NewEnterprise = (props: Props) => {
  const [title, setTitle] = useState<string>("Nouvelle Enterprise");
  const [name, setName] = useState<string>("");
  const [users, setUsers] = useState<string[] | undefined>([]);
  const [description, setDescription] = useState<string | undefined>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const enterprisesService = new EnterpriseService();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
 


  useEffect(() => {
    if (location.state?.update) {
      setIsUpdate(true);
      const enterpriseToUpdate: EnterpriseType = location.state.update;
      setName(enterpriseToUpdate.name);
      setUsers(enterpriseToUpdate.users);
      setDescription(enterpriseToUpdate.description);
      setTitle("Modification de la Enterprise");

    }
  }, [location.state]);

  const feedback = (res: { success: boolean; data: any }) => {
    const toastData: ToastType = {
      title: res.success ? "Enterprise enregistrée avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data }],
      status: res.success ? "success" : "error",
      position: "bottomRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);
    if (res.success) navigate("/enterprises");
  };

  useEffect(() => {
    setIsCompleted(name !== "");
  }, [name]);


  // const addEnterprise = () => {
  //   // Process the input when the user finishes typing (on blur)
  //   const processed = inputValue
  //     .split(/[;,]/) // Split by `;` or `,`
  //     .Enterprise((item: any) => item.trim()) // Trim whitespace
  //     .filter((item: any) => item); // Remove empty strings
  //   // Update the processed values
  // };

  const onSave = () => {

    const enterpriseValues: EnterpriseType = {
      name,
      users,
      description
    };

    const colModel = new EnterpriseModel(enterpriseValues);

    enterprisesService[isUpdate ? "updateEnterprise" : "createEnterprise"](
      colModel
    ).then((res) => feedback(res));
  };



  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/enterprises"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full max-w-xl gap-y-4 rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">* Le champ 'Nom' est obligatoire.</small>
        <InputText
          type="text"
          label="Name"
          id="name"
          classname="w-1/2"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <InputText
            type="text"
            label="Description"
            id="type"
            placeholder=""
            classname="w-full h-full"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        <div className="w-full flex gap-4 justify-left items-center">
          <div className="font-medium leading-6 text-gray-900 mb-0">
          Users
            <ul
              role="list"
              className="max-h-96 overflow-y-auto niceScroll mx-6 divide-y divide-blue-200 "
            >
              {users && users.map((user: string) => {
                return (
                  <li
                    className={
                      "w-full flex justify-between py-2 px-2"
                    }
                    key={user}
                  >
                    {user}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default NewEnterprise;

import { createBrowserRouter, redirect } from "react-router-dom";
import App from "../App";
import Collections from "../pages/collections/collections";
import Login from "../pages/login/login";
import Users from "../pages/users/users";
import Subscriptions from "../pages/subscriptions/subscriptions";
import NewSubscription from "../pages/subscriptions/subscriptionNew";
import NewCollection from "../pages/collections/collectionNew";
import NewUser from "../pages/users/userNew";
import CodeProjetRessources from "../pages/codeProjetRessource/codeProjetRessource";
import NewCodeProjetRessource from "../pages/codeProjetRessource/codeProjetRessourceNew";
import CatSansCat from "../pages/catSansCat";
import AccessDenied from "../pages/accessDenied";
import { jwtDecode } from "jwt-decode";
import UsersService from "../services/users.service";
import ImportCollections from "../pages/collections/collectionsImport";
import Maps from "../pages/maps/maps";
import Map from "../pages/maps/map";
import NewMap from "../pages/maps/mapNew";
import MapsService from "../services/maps.service";
import { DynamicHome } from "../pages/dynamicHome";
import Enterprises from "../pages/enterpises/enterprises";
import NewEnterprise from "../pages/enterpises/enterpriseNew";


interface PermissionsType {
  viewer: string[];
  reporter: string[];
  subscriber: string[];
  admin: string[];
}

const PERMISSIONS: PermissionsType = {
  viewer: ["/", "/maps", "/map"],
  reporter: ["/", "/subscriptions", "/newSubscription", "/maps", "/map"],
  subscriber: ["/", "/subscriptions", "/newSubscription", "/maps", "/map"],
  admin: ["/", "/subscriptions", "/newSubscription", "/maps", "/map", "/newMap", "/importCollections", "/collections", "/newCollection", "/users", "/newUser", "/codes", "/newCode", "/enterprises", "/newEnterprise"],
};

const checkPermission = async (props: any) => {
  const token = localStorage.getItem("token");
  if (token) {
    const url = new URL(props.request.url);
    const page = url.pathname;
    const decodedToken: { email: string; exp: number; iat: number } =
      jwtDecode(token);
    const usersService = new UsersService();

    const checkAccess = await usersService
      .getUser(decodedToken.email)
      .then((res) => {
        if (res.success && res.data) {
          const currentUser = res.data;
          const role = currentUser.role;
          // ensure authentic data
          localStorage.setItem("currentUser", JSON.stringify(currentUser));

          const checkPage =
            PERMISSIONS[role as keyof PermissionsType].includes(page);

          return checkPage ? role : redirect("/accessDenied?" + role);
        } else return redirect("/login");
      });

    return checkAccess;
  } else return redirect("/login");
};

const checkMap = async (props: any) => {
  const url = new URL(props.request.url);
  const params = new URLSearchParams(url.search);
  const name = params.get("name") || "";
  const mapService = new MapsService();
  const stringUser = localStorage.getItem("currentUser");
  if (stringUser) {
    const user = JSON.parse(stringUser);
    if (user) {
      const checkingMap = await mapService
        .getMap(name)
        .then((res:any) => {
          if (res.success && res.data) {
            const currentMap = res.data;
            const enterprises = currentMap.enterprises;
            // ensure authentic data
            const enterprise = user.enterprise;
            const checkPage = enterprises.includes(enterprise);

            return checkPage ? null : redirect("/accessDenied?" + enterprise);

          } else return redirect("/login");
        });

      return checkingMap;
    } else return redirect("/login");
  } else return redirect("/login");
};

const checkAuth = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return redirect("/login");
  }
  return null;
};


const combineLoaders = (...loaders: any[]) => async (args: any) => {
  for (const loader of loaders) {
    const result = await loader(args);
    if (result instanceof Response) {
      return result; // Stop execution and return the Response
    }
  }
  return null; // Return a default if no loader returns a Response
};

const loadDynamicHome = async (props: any)=> {
  return await checkPermission(props)
    .then((res) =>{
      return res; 
    })
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: checkAuth,
    children: [
      { path: "", loader: loadDynamicHome, element: <DynamicHome /> },
      {
        path: "subscriptions",
        loader: checkPermission,
        element: <Subscriptions />,
      },
      {
        path: "newSubscription",
        loader: checkPermission,
        element: <NewSubscription />,
      },
      {
        path: "importCollections",
        loader: checkPermission,
        element: <ImportCollections />,
      },
      {
        path: "collections",
        loader: checkPermission,
        element: <Collections />,
      },
      {
        path: "newCollection",
        loader: checkPermission,
        element: <NewCollection />,
      },
      { path: "users", loader: checkPermission, element: <Users /> },
      { path: "newUser", loader: checkPermission, element: <NewUser /> },
      {
        path: "codes",
        loader: checkPermission,
        element: <CodeProjetRessources />,
      },
      {
        path: "newCode",
        loader: checkPermission,
        element: <NewCodeProjetRessource />,
      },
      {
        path: "maps",
        loader: checkPermission,
        element: <Maps />,
      },
      {
        path: "newMap",
        loader: checkPermission,
        element: <NewMap />,
      },
      {
        path: "map",
        loader: combineLoaders(checkPermission, checkMap),
        element: <Map />,
      },
      { path: "enterprises", loader: checkPermission, element: <Enterprises /> },
      { path: "newEnterprise", loader: checkPermission, element: <NewEnterprise /> },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "accessDenied",
    element: <AccessDenied />,
  },
  {
    path: "*",
    element: <CatSansCat />,
  },
]);

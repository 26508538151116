import { CollectionModel, collectionType } from "../models/collection.model";
import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";
import { ReturnPagination } from "../models/return.model";
import { PaginationModel } from "../models/pagination.model";

export default class CollectionsService extends BaseService {
  constructor() {
    super();
  }
  createCollection = async (values: collectionType) => {
    if (!(values instanceof CollectionModel)) {
      throw new TypeError(
        'The collection must be an instance of class "collectiontype"'
      );
    }
    return await this.axios
      .post(`${this.apiUrl}/api/collection`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.CREATED].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return { data: error.message, success: false };
      });
  };

  updateCollection = async (values: collectionType) => {
    if (!(values instanceof CollectionModel)) {
      throw new TypeError(
        'The collection must be an instance of class "collectiontype"'
      );
    }

    return await this.axios
      .put(`${this.apiUrl}/api/collection/`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return { data: error.message, success: false };
      });
  };

  deleteCollection = async (idArk: string) => {
    return await this.axios
      .delete(`${this.apiUrl}/api/collection/`, {
        headers: this.getHeader(),
        data: {
          idArk: idArk,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          success: false,
        };
      });
  };

  getCollections = async (
    limit: number,
    page: number = 1,
    filter: any = {}
  ) => {
    return await this.axios
      .get(`${this.apiUrl}/api/collections`, {
        headers: this.getHeader(),
        params: {
          page: page,
          limit: limit,
          filter: filter
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if (
          [
            StatusCodes.CREATED,
            StatusCodes.NO_CONTENT,
            StatusCodes.ACCEPTED,
            StatusCodes.OK,
          ].includes(res.status)
        ) {
          const returnGet = res.data as ReturnPagination;
          const pagination = returnGet.data as PaginationModel;
          return {
            data: pagination,
            success: true,
          };
        }
       
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          success: false,
          redirect: this.checkError(error.response.status),
        };
      });
  };

  createCollections = async (values: Array<collectionType>) => {
    return await this.axios
      .post(`${this.apiUrl}/api/collections`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.CREATED].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return { data: error.message, success: false };
      });
  };
}

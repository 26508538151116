/**
 * @class Defines an user.
 */

export interface EnterpriseType {
  name: string;
  description?: string;
  users?: string[];
}

export class EnterpriseModel {
  name: string;
  description?: string;
  users?: string[];

  constructor(bo: EnterpriseType) {
    this.name = bo.name;
    this.description = bo.description;
    this.users = bo.users;
  }
}
